.file-preview-container {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  
  .frame-excel-content {
    flex: 1;
  }
}
