@use '../../assets/css/index.scss' as *;

.home-layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  &-content {
    height: 100%;
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: $grey10;
  }
  &-container {
    width: 100%;
    height: 100%;
    // minus for header bar
    max-height: calc(100vh - 50px);
    background-color: $grey10;
    box-sizing: border-box;
    flex: auto;
    overflow: auto;
    position: relative;
    padding: 0 15px;
    .message-bar {
      display: flex;
      flex-direction: column;
      gap: 10px;
      position: sticky;
      top: 0px;
      z-index: 99;
    }
  }
}