@use '../../../assets/css/index.scss' as *;

.unexpected-error-modal {
  .modal-wrapper {
    display: block;
    padding: 0;

    .list-content {
      margin-right: 24px;
    }
  }

  .modal-wrapper .modal-title {
    gap: 0;
    padding: 12px 12px 14px 24px;
    border-top: 4px solid $primaryColor;
  }

  .modal-container {
    overflow: hidden;
  }

  .modal-body {
    justify-content: center;
    height: calc(100% - 62px);
    padding: 0 30px;
    .ms-Modal-scrollableContent {
      overflow: hidden;
    }

    .unexpected-container {
      overflow-y: auto;

      div {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }

      .error-title {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: $errorColor;
      }

      .error-additional-info {
        margin-top: 2rem;
      }

      .stack-trace {
        padding: 1rem;
        margin-bottom: 1rem;
        background-color: $grey30;
      }
    }
  }

  @media (min-width: 768px) {
    .modal-container {
      height: calc(70% - 32px);
      width: calc(70% - 32px);
    }
  }

  @media (max-width: 767px) {
    .modal-container {
      height: calc(100% - 32px);
      width: calc(100% - 32px);
    }
  }

  .ms-Modal-scrollableContent {
    overflow: hidden;
  }
}
