@use '../../../assets/css/index.scss' as *;

.complete-uploading-modal {
  .modal-wrapper {
    display: block;
    padding: 0;

    .list-content {
      margin-right: 24px;
    }
  }

  .modal-wrapper .modal-title {
    gap: 0;
    padding: 12px 12px 14px 24px;
    border-top: 4px solid $primaryColor;
  }

  .modal-container {
    overflow: hidden;
  }

  .modal-body {
    justify-content: center;
    height: calc(100% - 62px);
    padding: 0 50px;
    align-items: center;
    text-align: center;

    .ms-Modal-scrollableContent {
      overflow: hidden;
    }

    .uploaded-processing-container {
      width: 500px;
      display: inline-block;

      * {
        padding: 0.5rem;
      }

      .title {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }

      
      .icon {
        font-size: xx-large;
      }

      .navigate-upload-history {
        margin-top: 1rem;
        color: $primaryColor;
        display: inline-block;
      }

      .navigate-upload-history:hover {
        cursor: pointer;
      }
    }
  }

  @media (min-width: 768px) {
    .modal-container {
      height: calc(70% - 32px);
      width: calc(70% - 32px);
    }
  }

  @media (max-width: 767px) {
    .modal-container {
      height: calc(100% - 32px);
      width: calc(100% - 32px);
    }
    
    .uploaded-processing-container { 
      max-width: 300px;
    }
  }

  .ms-Modal-scrollableContent {
    overflow: hidden;
  }
}
