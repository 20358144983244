@use '../../../assets/css/index.scss' as *;
$paddingLeftWaffleIcon: 15px;

.header {
  min-height: 50px;
  height: 50px;
  background-color: $primaryColor;
  display: flex;
  align-items: center;
  padding-left: $paddingLeftWaffleIcon;
  box-sizing: border-box;

  & > * {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .left-position {
    position: relative;
    color: $textColor;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box;

    .waffle-button i {
      color: $textColor;
    }

    .waffle-menu {
      position: fixed;
      top: 0;
      left: 0;
      width: 0;
      height: 100vh;
      background-color: $white;
      box-shadow: 0px 4.8px 14.4px rgba(0, 0, 0, 0.18), 0px 25.6px 57.6px rgba(0, 0, 0, 0.22);
      z-index: 1;
      transition: 0.3s;
      visibility: hidden;
      padding-left: $paddingLeftWaffleIcon;
      box-sizing: border-box;
      &-open {
        visibility: initial;
        z-index: 9999;
        width: 350px;
        @include screen-sm {
          width: 250px;
        }
        .waffle-button i {
          color: $grey130;
        }
      }
    }

    img {
      &.desktop {
        display: block;
      }
      &.mobile {
        display: none;
      }
      @include screen-sm {
        &.desktop {
          display: none;
        }
        &.mobile {
          display: block;
        }
      }
    }

    .divider-right {
      margin-left: 15px;
    }

    .company-div {
      cursor: pointer;
      display: flex;
      font-size: 13pt;
      padding-left: 10px;
      align-items: center;
      max-width: 300px;
      
      .company-span {
        margin-bottom: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .company-icon {
        padding-left: 8px;
      }
    }
    .company-div.company-default-cursor {
      cursor: default;
    }
  }

  .right-position {
    justify-content: flex-end;
    position: relative;
    flex: auto;
  }
}
