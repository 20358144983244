@use '../../../assets/css/index.scss' as *;

.validated-error-modal {
  .modal-wrapper {
    display: block;
    padding: 0;

    .list-content {
      margin-right: 24px;
    }
  }

  .modal-wrapper .modal-title {
    gap: 0;
    padding: 12px 12px 14px 24px;
    border-top: 4px solid $primaryColor;
  }

  .modal-container {
    overflow: hidden;
  }

  @media (min-width: 768px) {
    .modal-container {
      height: calc(70% - 32px);
      width: calc(70% - 32px);
    }
  }

  // .text-description {
  //   color: $errorColor;
  //   margin-left: 15px;
  // }

  // .modal-bottom {
  //   border-top: 1px solid $grey30;
  //   padding: 16px;
  //   text-align: right;
  //   margin-left: 15px;
  // }

  .error-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding: 3px 7px 0 7px;

    .error-icon {
      font-size: 40px;
      color: $errorColor;
    }

    .action-required-text {
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 0;
    }

    .invalid-description {
      margin: 0;
    }

    .download-error-link {
      font-size: 15px;
    }
  }

  .modal-wrapper .modal-body {
    height: calc(100% - 62px);
    align-items: center;
    justify-content: center;
  }
}
