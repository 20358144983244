@use '../../assets/css/index.scss' as *;

.upload-history {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  margin-bottom: 12px;
  border: solid 1px $grey30;
  border-radius: 0.1rem;
  height: calc(100% - 28px);
  box-shadow: 0 4px 8px 0 $grey30, 0 6px 20px 0 $grey30;
  background: $white;

  .upload-search {
    padding: 1rem;
    .ms-TextField-wrapper {
      width: 40%;
      position: absolute;
      right: 0;

      input {
        flex: 1
      }
    }
  }
  .content-body {
    margin-top: 1.5rem;
    padding: 1rem;
    flex: 1;

    .imported-file-container {
      display: flex;
      column-gap: 0.5rem;

      .link-imported {
        font-size: 14px;
        color: $primaryColor;
        text-decoration: underline;
        cursor: pointer; 
      }

      .icon-imported-excel {
        color: green;
        margin-top: 2px;
      }
    }
  }
}
