@use '../../../../assets/css/index.scss' as *;

.company-search {
  width: 100%;
  display: flex;
  gap: 8px;
  justify-content: end;

  .dropdown-type {
    min-width: 160px;
  }

  .search-box {
    min-width: 300px;
  }

  .ms-TextField.search-box {
    flex-grow: 0;
  }

  @include screen-md {
    .dropdown-type {
      min-width: 100px;
    }

    .ms-TextField.search-box {
      flex-grow: 1;
    }
  }

  @include screen-sm {
    flex-direction: column;
    .search-box {
      min-width: unset;
    }
  }

  @include screen-ssm {
    flex-direction: column;
    .search-box {
      min-width: unset;
    }
  }
}

.company-content-body {
  flex: 1;

  .ms-DetailsRow-fields div[data-automation-key='companyName'] {
    font-weight: 600;
  }

  .total-items {
    margin-top: 0.5rem;
    border-top: solid 1px $grey30;
  }

  .granted {
    color: $onixgood;
  }

  .restricted {
    color: $onixbad;
  }

  .pendingconsent {
    color: $onixpendingconsent;
  }

  .disabled-onix-access {
    opacity: 0.5;
  }
}

.company-footer {
  justify-content: end;
  display: flex;
  gap: 1rem;
}

