$primaryColor: #1a74ba;
$lighterBlue: #d2e5f4;
$textColor: #fafafa;
$white: #ffffff;
$black: #000000;
$grey10: #faf9f8;
$grey30: #edebe9;
$grey90: #a19f9d;
$grey130: #605e5c;
$grey110: #8a8886;
$grey190: #201f1e;
$grey160: #323130;
$red10: #d13438;
$bannerColor: #fea42c;
$bannerBlueColor: #0078D4;
$rowSelectedColor: #b3d9ff;
$rowHoverColor: #b3d9ff7c;
$errorColor: #a4262c;
$onixpendingconsent: #8d398e;
$onixgood: #00820F;
$onixbad: #C40000;
