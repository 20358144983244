@use '../../assets/css/index.scss' as *;
.home-layout {
  &-content {
    &-title {
      display: inline-block;
      width: 100%;
    }

    &-description {
      display: inline-block;
      width: 100%;
      background-color: $white;
      box-shadow: 0 3px 4px 0 lightgrey;
      border-radius: 3px;

      .description-col {
        width: 100%;
      }

      .description-col-content {
        text-align: center;
        padding: 30px 10px;

        .circle-number {
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          background-color: lightgrey;
          color: $black;
          font-size: 20px;
          font-weight: bold;
        }

        .second-text {
          padding-top: 10px;
          font-weight: bold;
        }
        .third-text,
        .link-text {
          padding: 3px 0;
        }

        .link-text {
          color: $primaryColor;
        }
      }

      @media (min-width: 768px) {
        .description-col {
          display: flex;

          div {
            -ms-flex: 1; /* IE 10 */
            flex: 1;
          }
        }
      }
    }

    .home-layout-upload-file {
      width: 100%;
      height: 263px;
      padding: 15px 0;
      background-color: $grey10;

      .upload-file-component {
        background-color: $white;
        box-shadow: 0 3px 4px 0 lightgrey;
        padding: 15px 0;
        border-radius: 3px;
        .drag-and-drop-zone {
          background-color: $white;
          margin: 0 15px;
        }
      }
    }
  }
}
