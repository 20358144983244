@use '../../assets/css/index.scss' as *;

.drag-and-drop-zone {
  $border-radius: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 25px 10px;
  border: 2px dashed $grey90;
  border-radius: $border-radius;
  position: relative;

  .select-file-input {
    display: none;
  }

  .upload-icon {
    font-size: 32px;
  }

  .upload-message {
    font-size: 20px;
    font-weight: 600;
  }

  &.drag-active {
    background-color: $lighterBlue !important;
  }
  &.drag-disabled {
    opacity: 0.4;
  }

  .dummy-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: $border-radius;
    inset: 0;
    transition: 0.3s ease all;
  }
}
