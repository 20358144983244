@mixin screen-ssm {
  @media (max-width: 320px) {
    @content;
  }
}

@mixin screen-sm {
  @media (max-width: 576px) {
    @content;
  }
}

@mixin screen-md {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin screen-lg {
  @media (max-width: 992px) {
    @content;
  }
}

@mixin screen-xl {
  @media (max-width: 1200px) {
    @content;
  }
}

@mixin screen-xxl {
  @media (max-width: 1600px) {
    @content;
  }
}