@use '../../../assets/css/index.scss' as *;

.validating-uploading-modal {
  .modal-wrapper {
    display: block;
    padding: 0;

    .list-content {
      margin-right: 24px;
    }
  }

  .modal-wrapper .modal-title {
    gap: 0;
    padding: 12px 12px 14px 24px;
    border-top: 4px solid $primaryColor;
  }

  .modal-container {
    overflow: hidden;
  }

  .modal-body {
    justify-content: center;
    height: calc(100% - 62px);
    padding: 0 50px;

    .ms-Modal-scrollableContent {
      overflow: hidden;
    }
  }

  @media (min-width: 768px) {
    .modal-container {
      height: calc(70% - 32px);
      width: calc(70% - 32px);
    }
  }

  // @media (max-width: 767px) {
  //   .modal-container {
  //     height: calc(100% - 32px);
  //     width: calc(100% - 32px);
  //   }
  // }

  .ms-Modal-scrollableContent {
    overflow: hidden;
  }
}
