@use "./index.scss" as *;
@import "../fonts/app-fonts.css";

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Segoe UI', Verdana, Helvetica, Sans-Serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;

 &::-webkit-scrollbar,
  & ::-webkit-scrollbar {
    width: 15px;
  }

  &::-webkit-scrollbar-track,
  & ::-webkit-scrollbar-track {
    background-color: $grey10;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb,
  & ::-webkit-scrollbar-thumb {
    border-radius: 100px;
    border: 3.5px solid transparent;
    background-clip: content-box;
    background-color: rgb(219, 217, 217);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.spinner-overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  z-index: 9999999999999999;
}

.text-three-dots {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.title {
  &-page {
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    color: $black;
  }

  &-section {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: $black;
  }
}

.form-default {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .row {
    display: flex;
    flex-direction: column;
    gap: 5px;

    & .controls {
      display: flex;
      align-items: center;

      & > * {
        flex: 1;
      }

      & > span:first-child {
        max-width: 150px;
      }
    }

    & .errors {
      display: flex;
      flex-direction: column;
      gap: 5px;
      color: $red10;
    }

    &-footer {
      display: flex;
      gap: 15px;
      margin-top: 20px;
    }
  }
}

.confirmation-dialog-footer {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.divider {
  &-right {
    width: 1px;
    background-color: $grey30;
    box-sizing: border-box;
    height: 70%;
  }
}

.text-grey-130 {
  color: $grey130;
}

.text-grey-190 {
  color: $grey190;
}

.ms-DetailsList.table-list, .ms-ScrollablePane:has(.table-list) {
  overflow: unset;
  .ms-DetailsHeader {
    padding: 0px;
    border-top: solid 1px $grey30;
    height: 30px;
    line-height: 30px;
    .ms-DetailsHeader-cell,.ms-DetailsHeader-cellIsCheck{
      border-left: solid 1px $grey30;
      border-collapse: collapse;
      height: 30px;
      line-height: 30px;

      .ms-DetailsHeader-cellTitle:hover{
        cursor: pointer;
        background-color: $grey30;
      }
    }
 
    .ms-DetailsHeader-cellIsCheck{
      border-left: none;
      height: 30px;
      line-height: 30px;
    }
  }

  .ms-DetailsRow {
    border-bottom: solid 1px $grey30;
    color: $grey160;
    min-width: 100% !important;

    &:hover {
      background-color: $rowHoverColor;
    }
    &.is-selected {
      background-color: $rowSelectedColor;

      .link-column, .cell-link {
        font-weight: bold;
      }
    }

    .ms-DetailsRow-cellCheck {
      .ms-DetailsRow-check{
        height: 100%;
      }

      .ms-Check {
        &.is-checked {
          &::before {
            background: $primaryColor;
          }
        }
      }
    }

    .ms-DetailsRow-cell:not(.ms-DetailsRow-cellCheck) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 13px;
      & *:not(.ms-Icon, i) {
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .content, .ms-Link{
        max-width: 100%;
      }
    }

    .ms-DetailsRow-fields:has(.row-opacity-blur) {
      opacity: 0.5 !important;
    }
  }

  .ms-Panel-footer{
    border-top: 1px solid $grey30,
  }

}

//for single selection
.ms-DetailsList.table-single-selection .ms-GroupHeader{
  .ms-GroupSpacer{
    display: none !important;
  }
  .ms-GroupHeader-dropIcon{
    display: none !important;
  }
  .ms-GroupHeader-title{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
}

//for icon column
.width40 { width: 40px !important;  }
.width60 { width: 60px !important; }
.width80 { width: 80px !important; }