@use "./index.scss" as *;

// Action button
.ms-Button.ms-Button--action.ms-Button--command {
    min-width: 48px;
    min-height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;;

    &.text-color i {
        color: $textColor;
    }

    &.grey-130 i {
        color: $grey130;
    }

    .card-container & {
        min-width: auto;
        min-height: auto;
    }
}

.ms-Button {
    & .content-button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 7px;
    }
}


.ms-Persona-details {
    .your-info-container &{
        display: none;
    }
}

.ms-TextField {
    &.suffix-transparent {
        .ms-TextField-suffix {
            background-color: transparent;
        }
    }
}