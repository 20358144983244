@use '../../../assets/css/index.scss' as *;

.upload-history .status-container {
  display: flex;
  flex-direction: 1;
  column-gap: 0.5rem;

  .empty-line {
    border-left: solid 1px black;
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .status-content {
    .error-view-detail {
      display: inline;
      font-size: 12px;
      color: #1a74ba;
      text-decoration: underline;
      cursor: pointer;
      margin-left: 4px;
    }
  }

  span.ms-layer {
    display: contents;
  }

  .processing-icon {
    cursor: pointer;
  }
}
