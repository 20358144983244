@use '../../../assets/css/index.scss' as *;

.file-detail-btn-action {
  display: flex;
  height: 32px;
  align-items: center;
  gap: 1rem;

  :hover {
    color: $primaryColor;
    cursor: pointer;
  }

  .btn-open, .btn-download {
    display: flex;
    gap: 6px;

    i {
      margin-top: 2px;
    }
  }
}

.frame-excel-content {
  background-color: whitesmoke;
  height: 100%;
}