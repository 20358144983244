@use '../../../assets/css/index.scss' as *;
.environment-bottom-bar {
    position: relative;
    bottom: 0px;
    right: 0;
    left: 0;
    height: 50px;
    padding-left: 10px;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: lowercase;
    font-size: 20px;
    font-weight: 400;
}

.environment-bottom-bar > span {
    color: #FFFFFF
}

.environment-bottom-bar > .capitalize {
    text-transform: capitalize;
    font-weight: 700;
}

.yellow {
    background-color: $bannerColor;
}

.blue {
    background-color: $bannerBlueColor;
}