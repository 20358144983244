@use '../../../assets/css/index.scss' as *;

.home-left-menu {
  background-color: $white;
  // width: 280px;
  // min-width: 280px;
  // @media (max-width: 767px) {
  //   width: 56px;
  //   min-width: 56px;
  // }

  height: 100%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid $textColor;
  transition: 0.2s;
  //max-width: 270px;

  // &.open {
  //   width: 26%;
  //   @include screen-sm {
  //     min-width: 200px;
  //     width: 200px;
  //   }
  // }

  &.narrow-menu {
    width: 56px;
  }

  &.wide-menu {
    width: 260px;
  }

  .menu-item {
    box-sizing: border-box;
    padding: 10px;
    min-height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    color: $grey190;
    cursor: pointer;
    &-icon {
      color: $primaryColor;
      &.collapse {
        cursor: pointer;
      }
    }
    & > span {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      display: inline-block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &-selected {
      background-color: $grey30;
      & > span {
        font-weight: 600;
      }
    }

    &.selection {
      & > button:hover {
        background-color: unset;
      }
      &:hover {
        color: $primaryColor;
      }
    }
  }
}
