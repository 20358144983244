@use '../../../assets/css/index.scss' as *;

.instruction-modal {
  .modal-wrapper {
    display: block;
    padding: 0;

    .list-content {
      margin-right: 24px;
    }
  }

  .modal-wrapper .modal-title {
    gap: 0;
    padding: 12px 12px 14px 24px;
    border-top: 4px solid $primaryColor;
  }

  .modal-container {
    overflow: hidden;
  }

  @media (min-width: 768px) {
    .modal-container {
      max-height: calc(70% - 32px);
      max-width: calc(70% - 32px);
    }
  }
}
