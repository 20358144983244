@use '../../../../assets/css/index.scss' as *;

.ms-Callout-container {
  .callout-container {
    width: 530px;
    padding: 20px 24px;
    .callout-title {
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
      color: $grey190;
      margin-bottom: 8px;
    }

    .callout-description {
      height: 65px;
      line-height: 16px;
      color: $grey190;
    }
  }

  @media (max-width: 767px) {
    .callout-container {
      width: 370px;
      height: 160px;
      padding: 20px 24px;
    }
  }
}
