@use '../../../../assets/css/index.scss' as *;

.account-action-container {
    display: inherit;
    justify-content: inherit;
    align-items: inherit;
    position: relative;
    .avatar-menu {
        width: 320px;
        min-height: 180px;
        position: absolute;
        top: 50px;
        right: 0px;
        margin-right: 20px;
        background: #FFFFFF;
        box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13);
        border-radius: 2px;
        padding: 0px 15px 10px 15px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 8px;
        z-index: 9999;
        .right-controls {
            display: flex;
            justify-content: flex-end;
            & > span {
                cursor: pointer;
            }
            .company-item {
                flex-grow: 1;
                align-self: center;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-right: 2.5rem;
            }
            .sign-out-btn {
                min-width: 70px;
            }
        }
        .information {
            flex: auto;
            display: flex;
            align-items: center;
            .name {
                display: flex;
                flex-direction: column;
                gap: 3px;
                min-width: 0;
                & span:first-child {
                    font-weight: 700;
                    word-break: break-word;
                }
                & span {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    .help-menu {
        position: absolute;
        top: 50px;
        right: 0;
        width: 0;
        height: 100vh;
        
        background-color: $white;
        box-shadow: 0px 4.8px 14.4px rgba(0, 0, 0, 0.18), 0px 25.6px 57.6px rgba(0, 0, 0, 0.22);
        transition: 0.3s;
        visibility: hidden;
        box-sizing: border-box;

        display: flex;
        flex-direction: column;
        color: $grey160;
        gap: 13px;
        z-index: 9999;

        .close-button-wrapper {
            display: flex;
            justify-content: flex-end;
        }

        .title {
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
            color: $grey190;
        }

        .version {
            display: flex;
            > span:first-child {
                max-width: 50%;
            }
            gap: 5px;
        }

        &.open {
            visibility: initial;
            width: 350px;
            padding: 0px 10px 20px 20px;
            @include screen-sm {
                width: 250px;
            }
        }
    }
}
